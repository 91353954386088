@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lora:wght@400;500;600;700&display=swap");
@mixin font-size($font-size, $line-height: normal) {
    font-size: $font-size * 1px;
    // font-size: $font-size * 0.1rem;
    // example using rem values and 62.5% font-size so 1rem = 10px

    @if $line-height==normal {
        line-height: normal;
    } @else {
        line-height: $line-height / $font-size;
    }
}
h1,
.font-46 {
    @include font-size(46, 60);
}
h2,
.font-38 {
    @include font-size(38, 49);
}
.font-28 {
    @include font-size(28, 42);
}
.font-20,
h5.font-20 {
    @include font-size(20, 30);
}
.font-18 {
    @include font-size(18, 27);
}
.font-16,
h6 {
    @include font-size(16, 24);
}
.font-14 {
    @include font-size(14, 19);
}
.font-15 {
    @include font-size(15, 22);
}
.font-14.ln-21 {
    @include font-size(14, 21);
}
.font-12 {
    @include font-size(12, 18);
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
