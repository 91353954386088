.topbar {
    min-height: 40px;
    a {
        color: $white;
        &:hover {
            opacity: 0.9;
        }
    }
    @include respond-below(md) {
        padding: 15px 4px 20px 4px;
        .top-contact-info {
            a {
                margin: 16px 0;
            }
        }
        .lang {
            .btn {
                padding: 0 10px;
            }
        }
    }
    // height: 40px;
}
.social {
    ul {
        li {
            a {
                width: 24px;
                height: 24px;
                line-height: 21px;
                margin-right: 20px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
.lang {
    .btn {
        opacity: 0.5;
        color: #fff !important;
        padding: 5px 10px;
        text-decoration: none;
        &.active {
            opacity: 1;
        }
    }
}

//bottom header
.navbar-brand {
    img {
        @include respond-below(md) {
            height: 40px;
        }
    }
}
.navbar {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    .nav-link {
        padding-top: 28px;
        padding-bottom: 28px;
        font-weight: 500;
        // font-size: $root-font-size;
        @include font-size(15, 24);
        &.active,
        &:hover {
            background-color: rgba($color: $success, $alpha: 0.1);
        }
        &[aria-expanded="true"] {
            background-color: rgba($color: $success, $alpha: 0.1);
        }
    }
}
.navbar-toggler {
    border: 0;
    padding: 0;
    font-size: 20px;
    position: relative;
    height: 27px;
    width: 24px;
    margin-left: auto;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 6px 0;
}
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}
.navbar-toggler span.icon-bar {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    background: $dark;
    width: 24px;
    height: 2px;
    display: block;
    text-align: right;
    margin-left: auto;
    opacity: 0.8;
}
.navbar-toggler span.icon-bar.bottom-bar {
    margin-bottom: 0px;
}
.navbar-toggler.active .icon-bar {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

.navbar-toggler.active .top-bar {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 12px;
}
.navbar-toggler.active .middle-bar {
    opacity: 0;
}
.navbar-toggler.active .bottom-bar {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 24px !important;
    position: absolute;
    top: 12px;
}

.navbar-collapse,
.navbar-collapse.collapsing {
    @include respond-below(md) {
        flex-basis: 100%;
        flex-grow: 1;
        position: fixed;
        height: calc(100vh - 60px) !important;
        height: calc(calc(var(--vh, 1vh) * 100) - 60px);
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 11;
        left: 0;
        bottom: 0;
        top: 60px;
        transform: translateX(-100%);
        transition: 0.3s transform;
        overflow-y: auto;
        background-color: $white;

        &.show {
            transform: translateX(0);
            transition: 0.3s;
        }
    }
}

@include respond-below(md) {
    .navbar-nav {
        background-color: $white;
        width: 100%;
    }
    .navbar {
        .nav-link {
            padding: 10px 16px;
            border-bottom: 1px solid $light;
        }
        .dropdown-menu {
            box-shadow: none;
            padding-left: 10px;
            .dropdown-item {
                border-bottom: 1px solid $light;
            }
        }
    }
}
