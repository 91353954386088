footer {
    color: rgba($color: $white, $alpha: 0.7);
    padding-bottom: 26px;
    .footer-heading {
        border-bottom: 2px solid $warning;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .social {
        margin-top: 10px;
        a {
            color: rgba($color: $white, $alpha: 0.7);
            border-color: rgba($color: $white, $alpha: 0.7);
            &:hover {
                color: rgba($color: $white, $alpha: 1);
                border-color: rgba($color: $white, $alpha: 1);
            }
        }
    }
}
.footer-nav {
    li {
        width: 100%;
        .nav-link {
            color: rgba($color: $white, $alpha: 0.7);
            display: block;
            padding: 10px 20px 10px 0;
            &:hover {
                color: rgba($color: $white, $alpha: 1);
            }
        }
    }
}
.footer-link {
    color: rgba($color: $white, $alpha: 0.7);
    &:hover {
        color: rgba($color: $white, $alpha: 1);
    }
}
.footer-col {
    margin-top: 50px;
    @include respond-below(xs) {
        margin-top: 24px;
    }
}

.footer-inner {
    @include respond-below(md) {
        margin-top: 20px;
    }
}
