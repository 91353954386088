//Bootstrap initializaton

//@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../../node_modules/bootstrap/scss/functions.scss";

// 2. Include any default variable overrides here
@import "base/variables";
// 3. Include remainder of required Bootstrap stylesheets

@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

// eafoods badge custom mixins, variable and loop start ------------
@mixin badge-outline-variant(
    $color,
    $color-hover: color-contrast($color),
    $active-background: $color,
    $active-border: $color,
    $active-color: color-contrast($active-background)
) {
    color: $color !important;
    border-color: $color;
}

//
.badge {
    display: inline-block;
    color: $body-color;
    text-align: center;
    background-color: transparent;
    border: $btn-border-width solid transparent;
}

//loop
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-outline-variant($value);
    }
}
// eafoods badge custom mixins, variable and loop end ---------

@import "../../../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/helpers";

@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/button-group";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/card";
@import "../../../../node_modules/bootstrap/scss/accordion";
@import "../../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../../node_modules/bootstrap/scss/pagination";
@import "../../../../node_modules/bootstrap/scss/badge";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/progress";
@import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/close";
@import "../../../../node_modules/bootstrap/scss/toasts";
@import "../../../../node_modules/bootstrap/scss/modal";
@import "../../../../node_modules/bootstrap/scss/tooltip";
@import "../../../../node_modules/bootstrap/scss/popover";
@import "../../../../node_modules/bootstrap/scss/carousel";
@import "../../../../node_modules/bootstrap/scss/spinners";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities`
@import "../../../../node_modules/bootstrap/scss/utilities/api";

// //bs end

// //custom variables

// mixins
@import "mixins/mixins";

//typography
@import "typography/typography";

//base
@import "base/media-queries";
@import "base/base";
@import "components/components";
