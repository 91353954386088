body {
    $font-family-sans-serif: "Inter", sans-serif;
}

.main-wrapper {
    // max-width: 1440px;
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lora", serif;
    font-weight: 600;
}
.preloader {
    background-color: rgba($color: #f7f7f7, $alpha: 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;
}
.lora {
    font-family: "Lora", sans-serif;
}
.inter {
    font-family: "Inter", sans-serif;
}
.body-wrapper {
    padding-top: 60px;

    @include respond-above(md) {
        padding-top: 120px;
    }
}

.ic {
    height: 24px;
    width: 24px;
}
.ic-18 {
    height: 18px;
    width: 18px;
}
svg {
    color: inherit;
    fill: currentColor;
}

a {
    text-decoration: none;
}

.text-hover-primary {
    &:hover {
        color: $primary !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.h-18px {
    height: 18px;
}
// .min-h72px {
//     min-height: 72px;
// }
.w-1px {
    width: 1px;
}

.w-56px {
    width: 56px;
}

.w-300px {
    width: 300px;
}

.w-330px {
    width: 330px;
}

.mt-50px {
    margin-top: 50px;
}

.mt-30px {
    margin-top: 30px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-10px,
.mb-10 {
    margin-bottom: 10px;
}

.pb-10px {
    padding-bottom: 10px;
}

.pb-20px {
    padding-bottom: 20px;
}

.mb-20px {
    margin-bottom: 20px;
}

.py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pt-50px {
    padding-top: 50px;
}

.pb-50px {
    padding-bottom: 50px;
}

.border-color {
    border-color: $border;
}

.rounded-4 {
    border-radius: 4px;
}

//bg
.bg-story-detail {
    background-color: rgba($color: $success, $alpha: 0.1);
}
.story-avtar {
    height: 100%;
    object-fit: cover;
    @include respond-below(sm) {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-top-left-radius: 0 !important;

        &.blog-story-avtar {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }
    }
}
.story-picture {
    max-height: 410px;
    min-height: 410px;
    width: 100%;
    object-fit: cover;
}
.story-content * {
    color: #111111 !important;
    font-size: 16px !important;
}
//truncate
.truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.truncate-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

//dropdown menu
.dropdown-toggle::after {
    border: 0;
    height: 22px;
    width: 24px;
    @include svg-bg-icon(arrow-bottom, $body-color);
    background-size: 13px;
    float: right;
    background-position: right;
}
.dropdown-toggle.active {
    &:after {
        @include svg-bg-icon(arrow-bottom, $success);
        background-size: 13px;
        float: right;
        background-position: right;
    }
}

.dropdown {
    &.show {
        .dropdown-toggle::after {
            @include svg-bg-icon(arrow-top, $success);
            background-size: 13px;
            float: right;
            background-position: right;
        }
        .dropdown-toggle.active {
            &:after {
                @include svg-bg-icon(arrow-top, $success);
                background-size: 13px;
                float: right;
                background-position: right;
            }
        }
    }
}
//NAv tabs
.nav-light {
    .nav-link {
        color: $body-color;
        font-weight: 600;
        padding: 20px 15px;
        border-bottom: 2px solid transparent;

        &.active {
            color: $primary;
            border-bottom-color: $primary;
        }
    }
}

//.card
.card {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.card-footer {
    padding: 20px;
}

//hero banner css

.dir-hero-img {
    direction: rtl;
}
.hero-banner {
    height: 500px;
    display: flex;
    align-items: center;
    --gradient-dir: 0deg;
    padding: 0 0;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    .hero-overlay {
        inset: 0;
        background: -webkit-linear-gradient(
            var(--gradient-dir),
            rgba(255, 255, 255, 1) 45.07%,
            rgba(255, 255, 255, 0) 76.11%
        );
        background: -moz-linear-gradient(
            var(--gradient-dir),
            rgba(255, 255, 255, 1) 45.07%,
            rgba(255, 255, 255, 0) 76.11%
        );
        background: -ms-linear-gradient(
            var(--gradient-dir),
            rgba(255, 255, 255, 1) 45.07%,
            rgba(255, 255, 255, 0) 76.11%
        );
        background: -o-linear-gradient(
            var(--gradient-dir),
            rgba(255, 255, 255, 1) 45.07%,
            rgba(255, 255, 255, 0) 76.11%
        );
        background: linear-gradient(var(--gradient-dir), rgba(255, 255, 255, 1) 45.07%, rgba(255, 255, 255, 0) 76.11%);
    }
    @include respond-below(md) {
        background-position: top right 35%;
    }
    @include respond-below(sm) {
        height: auto;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        background-image: none !important;
        flex-flow: column-reverse;

        .hero-responsive {
            height: 480px;
            object-fit: cover;
            width: 100%;
        }
        .hero-overlay {
            background: -webkit-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 44.07%,
                rgba(255, 255, 255, 0) 67.11%
            );
            background: -moz-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 44.07%,
                rgba(255, 255, 255, 0) 67.11%
            );
            background: -ms-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 44.07%,
                rgba(255, 255, 255, 0) 67.11%
            );
            background: -o-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 44.07%,
                rgba(255, 255, 255, 0) 67.11%
            );
            background: linear-gradient(var(--gradient-dir), rgb(255, 255, 255) 44.07%, rgba(255, 255, 255, 0) 67.11%);
        }
    }
    @include respond-below(xs) {
        .hero-responsive {
            height: 320px;
            object-fit: cover;
        }
        .hero-overlay {
            bottom: auto;
            height: 160px;
            top: 190px;
            background: -webkit-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 21.07%,
                rgba(255, 255, 255, 0) 79.11%
            );
            background: -moz-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 21.07%,
                rgba(255, 255, 255, 0) 79.11%
            );
            background: -ms-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 21.07%,
                rgba(255, 255, 255, 0) 79.11%
            );
            background: -o-linear-gradient(
                var(--gradient-dir),
                rgb(255, 255, 255) 21.07%,
                rgba(255, 255, 255, 0) 79.11%
            );
            background: linear-gradient(var(--gradient-dir), rgb(255, 255, 255) 21.07%, rgba(255, 255, 255, 0) 79.11%);
        }
    }
    @include respond-above(sm) {
        --gradient-dir: 90deg;
    }
    @include respond-above(xxl) {
        height: 700px;
    }
}
.homehero-banner {
    overflow: hidden;
    height: 648px;
    padding: 0 0;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 90%;
    .hero-overlay {
        inset: 0;
    }
    @include respond-below(sm) {
        height: auto;
        .hero-overlay {
            bottom: auto;
            top: 190px;
        }
    }
}
.hero-vector {
    max-height: 500px;
    @include respond-below(md) {
        max-width: 100%;
    }
}

.about-banner {
    height: 420px;
}

.about-hero-vector {
    margin-top: -180px;
}

.herotitle {
    font-weight: 700;
    margin-bottom: 10px;

    &:after {
        content: "";
        width: 25%;
        // width: 44%;
        height: 2px;
        display: block;
        background-color: $body-color;
        margin-top: 10px;
    }

    &.herotitlecenter {
        &:after {
            margin: 10px auto 0 auto;
        }
    }
}

// .HomepageHeroGraphic {
//     position: relative;
//     z-index: 2;
// }
.bg-hero-shape {
    position: absolute;
    right: 0;
    width: 70%;
    z-index: -1;
    bottom: 0;
    @include respond-below(md) {
        top: auto;
    }
}
.section-padding {
    padding-top: 70px;
    padding-bottom: 70px;
}
.brand-section {
    padding-top: 50px;
    padding-bottom: 50px;
}
.mt-70px {
    margin-top: 70px;
}
//blog page
// .blog-title {
//     min-height: 48px;
// }
.blogdate {
    min-width: 100px;
}
.sort-group {
    span {
        flex-basis: 90px;
    }

    select {
        width: 150px;
    }
}
.list-group-item {
    &.selected {
        color: $primary;
    }
    &:hover {
        color: $dark;
        @include respond-above(lg) {
            color: $primary;
        }
    }
}
.categoryBar {
    @include respond-above(md) {
        width: 300px;
        padding-top: 85px;
    }

    @include respond-below(md) {
        .category-inner {
            position: absolute;
            // top: 0;
            top: -50px;
            left: 15px;
            right: 15px;
            z-index: 11;
        }
    }
    .btn.form-select {
        border-radius: 4px !important;
        font-weight: 400;
    }
}
.category-collapse {
    @include respond-below(md) {
        ul {
            border-left: 1px solid rgba(0, 0, 0, 0.125);
            border-right: 1px solid rgba(0, 0, 0, 0.125);
        }
    }
}
.category-collapse.collapse {
    @include respond-above(md) {
        display: block;
    }

    @include respond-below(md) {
        overflow: hidden;
        border-radius: 0px 0px 8px 8px;
        box-shadow: 0px -2px 0px #0c78a4, 0px 12px 30px rgb(0 0 0 / 12%);
    }
}

.category-collapse.collapsing {
    overflow: initial;
    transition: none !important;
    height: auto !important;
    @include respond-below(md) {
        overflow: initial;
        transition: none !important;
        height: auto !important;
        box-shadow: 0px -2px 0px #0c78a4, 0px 12px 30px rgb(0 0 0 / 12%);
    }
}

.bloglisting {
    padding-top: 52px;

    @include respond-above(md) {
        padding-left: 26px;
        padding-top: 0;
    }
}

.blog-banner {
    position: relative;
    padding: 140px 0;
    min-height: 400px;

    .blogheroimg {
        position: absolute;
        inset: 0;
        z-index: 0;
        object-fit: cover;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    //background-image: url(../../../../../public/media/images/blogbanner.jpg);
    &::before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        top: 0;
        content: "";
        background-color: $body-color;
        opacity: 0.5;
    }
}

.blog-banner-title {
    z-index: 11;
}

.blog-alert {
    padding: 50px 40px;
    background: rgba(12, 120, 164, 0.05);
    border: 2px solid $primary;
    border-radius: 8px;
    margin-bottom: 50px;
}

//
//form
.form-search {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.75 3.5C6.29822 3.5 3.5 6.29822 3.5 9.75C3.5 13.2018 6.29822 16 9.75 16C13.2018 16 16 13.2018 16 9.75C16 6.29822 13.2018 3.5 9.75 3.5ZM2 9.75C2 5.46979 5.46979 2 9.75 2C14.0302 2 17.5 5.46979 17.5 9.75C17.5 14.0302 14.0302 17.5 9.75 17.5C5.46979 17.5 2 14.0302 2 9.75Z' fill='%236D6E6F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.2197 14.2197C14.5126 13.9268 14.9874 13.9268 15.2803 14.2197L21.2803 20.2197C21.5732 20.5126 21.5732 20.9874 21.2803 21.2803C20.9874 21.5732 20.5126 21.5732 20.2197 21.2803L14.2197 15.2803C13.9268 14.9874 13.9268 14.5126 14.2197 14.2197Z' fill='%236D6E6F'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-size: 24px 24px;
    padding-left: 40px;
}

.file-input {
    @include svg-bg-icon(file, $dark);
    background-size: 24px;
    background-color: $white;
    background-position: center right 10px;
}
.input-group {
    border: 1px solid $border;
    border-radius: $input-border-radius;

    .input-group-text {
        border: 0;
        border-color: inherit !important;
    }

    input {
        border: 0;
        border-color: $primary !important;
    }

    &:focus-within {
        border-color: $primary !important;
    }

    &.is-invalid-grp {
        border-color: $danger !important;
    }
}
// button group
.btngrp-space {
    .btn {
        margin-right: 10px;
        padding: 13px 23px;
        margin-bottom: 10px;

        @include respond-below(md) {
            padding: 8px 10px;
        }
    }
}

//pagination
.pagination {
    justify-content: center;
}

.page-item .page-link {
    min-width: 30px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.page-item {
    // margin-right: $pagination-item-space;

    &:last-child {
        margin-right: 0;
    }

    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;

        &.previous,
        &.next {
            display: block;
            background-size: 10px;
            background-color: $white;
            box-shadow: 5px 0px 12px rgba(0, 0, 0, 0.1);

            span {
                visibility: hidden;
            }
        }

        &.previous {
            @include svg-bg-icon(arrow-start, $body-color);
        }

        &.next {
            @include svg-bg-icon(arrow-end, $body-color);
        }
    }

    /*rtl:options:{"autoRename":false}*/

    &:focus {
        .page-link {
            &.previous {
                @include svg-bg-icon(arrow-start, $body-color);
            }

            &.next {
                @include svg-bg-icon(arrow-end, $body-color);
            }
        }
    }

    &:hover:not(.offset) {
        .page-link {
            &.previous {
                @include svg-bg-icon(arrow-start, $body-color);
            }

            &.next {
                @include svg-bg-icon(arrow-end, $body-color);
            }
        }
    }

    &.active {
        .page-link {
            &.previous {
                @include svg-bg-icon(arrow-start, $body-color);
            }

            &.next {
                @include svg-bg-icon(arrow-end, $body-color);
            }
        }
    }

    &.disabled {
        .page-link {
            &.previous {
                @include svg-bg-icon(arrow-start, #ddd);
            }

            &.next {
                @include svg-bg-icon(arrow-end, #ddd);
            }
        }
    }

    /*rtl:end:ignore*/
}

//success stories css start
.success-stories-section {
    padding-top: 50px;
}

//contact page css
.animated-shape {
    position: absolute;
    bottom: 70px;
    z-index: -1;
    @include respond-below(md) {
        width: 130px;
        top: 10%;
    }
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

//investor page
.inv-card {
    padding: 30px;
}

.brand-card {
    padding: 0 5px;
    max-width: 80px;
    min-width: 80px;
    min-height: 80px;
    border: 1px solid $border;
    &.selected {
        border-color: $primary !important;
        box-shadow: 0 0 1px 1px $primary;
    }
    &:hover {
        @include respond-above(lg) {
            border-color: $primary !important;
            box-shadow: 0 0 1px 1px $primary;
        }
    }
    img {
        max-width: 100%;
    }
}

//testimonial
.tm-card {
    .card-body {
        // min-height: 270px;
        min-height: 136px;
    }
}
.tm-video {
    .card-body {
        min-height: 210px;
    }
}
.tm-logo {
    height: 100px;
    width: 100px;
}

.img-videoplaceholder {
    inset: 0;
    max-width: 100%;
    width: 100%;
}

.img-videoplaceholder > div > div {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.img-videoplaceholder > div > div::after {
    padding-top: 56.25%;
    display: block;
    content: "";
}

.img-videoplaceholder > div > div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-overlay {
    inset: 0;
    background: rgba(17, 17, 17, 0.3);
}

.icon-video {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: animate-pulse 1s linear infinite;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.5);
    line-height: 46px;
    border-radius: 50%;
    text-align: center;
}

.pulse {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

// @keyframes animate-pulse {
//     0% {
//         box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7), 0 0 0 0 rgba(255, 255, 255, 0.7);
//     }
//     40% {
//         box-shadow: 0 0 0 20px rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0.7);
//     }
//     80% {
//         box-shadow: 0 0 0 20px rgba(255, 255, 255, 0), 0 0 0 10px rgba(255, 255, 255, 0);
//     }
//     100% {
//         box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 10px rgba(255, 255, 255, 0);
//     }
// }

@-webkit-keyframes animate-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes animate-pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

// client logo
.client-logo {
    //  padding: 15px;
    // width: 190px;
    height: 100%;
    padding: 15px;

    img {
        //  margin: 15px;
        // width: 160px;
        width: 100%;
        max-width: 100%;
        //  padding: 15px;
    }
}

.slick-track {
    display: flex !important;
    justify-content: center;
    margin: 0 auto;

    @include respond-below(md) {
        transform: translate3d(0, 0, 0);
    }
}

.slick-slide {
    height: inherit !important;
}

.slick-slide > div {
    padding: 0 15px;
    height: 100%;
    // display: flex;
    // flex-direction: column;
}

.slick-track:after,
.slick-track:before {
    content: none;
}

//product detail
.product-lgimg {
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.12);
}

// accordion css
.ea-accordion {
    .accordion-button:not(.collapsed) {
        color: $body-color;
        background-color: transparent;
        box-shadow: none;
    }

    .accordion-button {
        padding: 20px 0;
        // font-family: $font-family-sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        // &::after {
        //     @include svg-bg-icon(accordion, $body-color);
        //     background-size: 13px;
        // }
    }

    .accordion-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0;

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

//career
.primary-shadow {
    min-height: 320px;
    position: relative;
    z-index: 1;

    img {
        box-shadow: 0px 30px 40px rgb(0 0 0 / 10%);
    }
    &:after {
        content: "";
        width: 300px;
        height: 300px;
        background: $primary;
        position: absolute;
        z-index: -1;
        border-radius: 8px;

        @include respond-below(lg) {
            width: 200px;
            height: 200px;
        }

        @include respond-below(sm) {
            width: 150px;
            height: 150px;
        }
    }

    @include respond-below(md) {
        min-height: 200px;
    }
}

.left-shadow {
    padding-left: 20px;
    padding-top: 20px;

    &:after {
        left: 0;
        top: 0;
    }
}

.right-shadow {
    padding-right: 20px;
    padding-bottom: 20px;

    &:after {
        right: 0;
        bottom: 0;
    }
}

.career-card {
    padding: 40px 30px;
}

.apply-row {
    padding: 30px 0;
    box-shadow: inset 0px -1px 0px #ebebeb;
}

.apply-form {
    padding: 30px 50px;
}

.apply-now-section {
    margin-top: -100px;
    z-index: 1;
}

.modal-content {
    border: 0;
}

// about
.why-choose-img {
    height: 520px;
    object-fit: cover;
    transition: all 2s ease;
    @include respond-below(md) {
        height: 290px;
        margin-bottom: 30px;
    }
}
.flow-row {
    @include respond-below(md) {
        flex-direction: column;
        align-items: center;
    }
}

.flow-div.col {
    position: relative;
    margin-left: -30px;
}

.flow-div.col.first-div {
    margin-left: 5px;
    position: relative;
}

.flow-div.col {
    border-radius: 50%;
    text-align: center;
    height: 224px;
    width: 224px;
    max-width: 224px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-above(xl) {
        height: 224px;
        width: 224px;
        max-width: 224px;
    }
    @include respond-below(lg) {
        height: 220px;
        width: 220px;
        max-width: 220px;
    }

    @include respond-below(md) {
        height: 224px;
        min-height: 224px;
        width: 224px;
        max-width: 224px;
        margin-left: 0;
        margin-bottom: 30px;
    }
}

.flow-div.col h6 {
    margin-bottom: 10px;
}

.flow-div.col p {
    margin-bottom: 0;
}

.bg-light-primary {
    background: rgba(12, 120, 164, 0.15);
}

.bg-light-danger {
    background: rgba(208, 31, 69, 0.15);
}

.bg-light-success {
    background: rgba(10, 147, 71, 0.15);
}

.founded:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='134' height='134' viewBox='0 0 134 134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 133.008C1.25 115.672 4.66445 98.5068 11.2984 82.4911C17.9323 66.4753 27.6559 51.9231 39.9138 39.6651C52.1717 27.4072 66.724 17.6837 82.7397 11.0497C98.7555 4.41579 115.921 1.00134 133.256 1.00134' stroke='%236D6E6F' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 126px;
    height: 126px;
    position: absolute;
    left: -26px;
    top: -26px;
}
.ea_customers,
.founded,
.famer_saved,
.app,
.employee,
.delivery {
    &:after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        position: absolute;
        width: 126px;
        height: 126px;
        background-image: url("data:image/svg+xml,%3Csvg width='134' height='134' viewBox='0 0 134 134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 133.008C1.25 115.672 4.66445 98.5068 11.2984 82.4911C17.9323 66.4753 27.6559 51.9231 39.9138 39.6651C52.1717 27.4072 66.724 17.6837 82.7397 11.0497C98.7555 4.41579 115.921 1.00134 133.256 1.00134' stroke='%236D6E6F' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }
}
.ea_customers,
.app {
    &:after {
        left: 45px;
        bottom: -50px;
        -ms-transform: rotate(136deg) scalex(-1);
        -webkit-transform: rotate(136deg) scalex(-1);
        -moz-transform: rotate(136deg) scalex(-1);
        transform: rotate(136deg) scalex(-1);
    }
}

.famer_saved,
.employee {
    &:after {
        left: 45px;
        top: -50px;
        -ms-transform: rotate(44deg);
        -webkit-transform: rotate(44deg);
        -moz-transform: rotate(44deg);
        transform: rotate(44deg);
    }
}

.delivery {
    &:after {
        top: -26px;
        right: -26px;
        -ms-transform: rotate(89deg);
        -webkit-transform: rotate(89deg);
        -moz-transform: rotate(89deg);
        transform: rotate(89deg);
    }
}
//
.whatsappchat {
    position: fixed;
    right: 45px;
    bottom: 35px;
    z-index: 11;
    @include respond-below(md) {
        right: 20px;
        bottom: 40px;
    }
}
.whatsappchat img {
    width: 66px;
    height: 66px;
}
.home-brand > div {
    height: 190px;
    width: 190px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    transition: 0.3s;
    &:hover {
        border: 1px solid $dark;
        box-shadow: 0 0 1px 1px $dark;
    }
    @include respond-below(sm) {
        height: 140px;
        width: 140px;
    }
}
.home-brand {
    img {
        padding: 15px;
        border-radius: 50%;
    }
}
.counter {
    padding: 24px 50px;
}
.count-num {
    font-weight: 700;
    font-size: 60px;
    line-height: 78px;
    font-family: "Lora", sans-serif;
    min-width: 225px;
}
.center-count {
    @include respond-above(md) {
        border-left: 2px solid rgba($color: #fff, $alpha: 0.5);
        border-right: 2px solid rgba($color: #fff, $alpha: 0.5);
    }
}
.counter-section {
    padding: 50px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include respond-above(xl) {
        padding: 90px 0;
    }
    .sep {
        width: 38px;
        height: 2px;
        background: #ffffff;
    }
    video {
        position: absolute;
        inset: 0;
    }
    .counter-overlay {
        position: absolute;
        inset: 0;
        background: #111111;
        opacity: 0.25;
    }
}
.error-page {
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
}
.view-more {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $border;
    border-width: 1px 0 0 0;
    @include respond-above(md) {
        border: 0;
    }
}
@include respond-below(md) {
    .flow-div.col {
        &:after {
            content: none;
        }
    }
    .about-hero-vector {
        margin-top: -80px;
    }

    .section-padding {
        padding: 50px 0;
    }

    .mw-100 {
        width: 100%;
    }

    .post-banner {
        padding: 40px 0;
    }

    .apply-form {
        padding: 30px 20px;
        margin-top: 20px;
    }
    .home-brand > div {
        height: 150px;
        width: 150px;
        overflow: hidden;
        padding: 20px;
        img {
            max-width: 100%;
        }
    }
}
.react-share__ShareButton {
    display: block !important;
    text-indent: 0% !important;
    height: 40px;
    width: 40px;
    border: 1px solid $primary !important;
    border-radius: 50%;
    &:hover {
        opacity: 0.8;
    }
}

// react select

.react-select-container {
    .react-select__control {
        border-color: $border;
        min-height: 43px;
        box-shadow: none;
        border-radius: 4px;

        &:hover {
            border-color: $border;
        }

        // &:focus {
        //   border-color: $primary;
        // }
        &.react-select__control--is-focused {
            border-color: $primary;
        }

        .react-select__value-container {
            padding-left: 10px;
            padding-right: 4px;

            .react-select__placeholder {
                color: $body-color;
            }
        }
    }

    .react-select__indicator {
        padding: 5px 6px 5px 0 !important;
        color: $text-muted;
        transition: none !important;

        &:hover {
            color: $text-muted;
        }
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__loading-indicator {
        display: none !important;
    }

    .react-select__multi-value {
        border-radius: 0.42rem;
        flex-direction: row-reverse;
        background-color: $light;

        .react-select__multi-value__label {
            padding-left: 0;
            padding-right: 10px;
        }

        .react-select__multi-value__remove:hover {
            color: inherit;
            background-color: transparent;
        }
    }

    .react-select__menu {
        box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
        margin-top: 5px;
        font-size: 16px;
        z-index: 999;

        .react-select__menu-list {
            font-size: 16px;
            padding: 0;

            .react-select__option {
                padding: 0.5125rem 0.875rem;
                font-size: 16px;

                &:first-child {
                    border-top-left-radius: 0.325rem;
                    border-top-right-radius: 0.325rem;
                }

                &:last-child {
                    border-bottom-left-radius: 0.325rem;
                    border-bottom-right-radius: 0.325rem;
                }

                &.react-select__option--is-selected {
                    background-color: $primary;
                    color: $white;
                }

                &.react-select__option--is-focused:active,
                &:active {
                    color: $dark;
                }

                &.react-select__option--is-selected:active {
                    color: $white;
                }
            }
        }
    }
}

.is-invalid .react-select__control {
    border-color: $danger;
}

.is-invalid .react-select__control:hover {
    border-color: $danger;
}

.skeleton-box {
    display: inline-block;
    height: auto;
    position: absolute;
    overflow: hidden;
    background-color: #dddbdd;
    inset: 0;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
