// @function toRem($value) {
//     $remValue: ($value / 16) + rem;
//     @return $remValue;
// }

// Root font Sizes
$root-font-size: 15px; // Root font size for desktop mode
$root-font-size-lg: 14px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode
$font-family-sans-serif: "Inter", sans-serif;
//colors
$body-color: #111;
$primary: #0c78a4;
$primary-light: #00afba;
$secondary: #6d6e6f;
$text-muted: #6d6e6f;
$success: #0a9347;
$danger: #d01f45;
$warning: #f9c527;
$dark: #111111;
$text: #111111;
$border: #ebebeb;
$light: #f7f7f7;
$white: #ffffff;
$lightgray: #ebebeb;

//gutter space
$grid-gutter-width: 1.875rem;

//radius
$btn-radius: 30px;
$chip-radius: 28px;
$border-radius: 8px;
$input-border-radius: 4px;
//navbar
$navbar-nav-link-padding-x: 15px;
$navbar-brand-padding-y: 10px;
$navbar-light-color: $body-color;
$navbar-light-active-color: $success;
$navbar-light-hover-color: $body-color;
//button
$btn-padding-y: 8.5px;
$btn-padding-x: 28px;
$input-btn-font-size: 16px;
$btn-line-height: 24px;
$btn-font-weight: 600;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-width: 0;
//dropdown
$dropdown-border-radius: 0px 0px 8px 8px;
$dropdown-border-width: 0;
$dropdown-padding-y: 0;
$dropdown-color: $body-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $white;
$dropdown-item-padding-y: 14px;
$dropdown-item-padding-x: 15px;
$dropdown-font-size: 14px;
$dropdown-inner-border-radius: subtract(8px, 0);
$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $white;

//badge
$badge-font-size: 14px;
$badge-font-weight: 600;
$badge-padding-y: 5px;
$badge-padding-x: 10px;
$badge-border-radius: 28px;

//card
$card-spacer-y: 20px;
$card-spacer-x: 20px;
$card-border-radius: $border-radius;

//form
$input-padding-y: 9.5px;
$input-padding-x: 10px;
$form-select-focus-box-shadow: none;
$input-focus-box-shadow: none;
$input-border-color: $border;
//$form-select-font-weight: 600;
//list group
$list-group-item-padding-y: 10px;

//pagination
$pagination-margin-start: 5px;
$pagination-padding-y: 5px;
$pagination-padding-x: 5px;
$pagination-border-radius: 4px;

//accordion
$accordion-button-focus-box-shadow: none;

//modal
$modal-content-border-radius: $border-radius;
