.dropdown-menu {
    box-shadow: 0px -2px 0px #0c78a4, 0px 12px 30px rgb(0 0 0 / 12%);
}
.dropdown-item {
    font-weight: 500;
    &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
